import React from 'react'

import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { differenceInDays } from 'date-fns'

import { useReport, useForecast } from './Context'
import { DateTime } from 'components/time'
import { Metadata, Entry, ShareEntry, TimestampEntry } from 'components/metadata'

ForecastMetadata.propTypes = {
    shareUrl: PropTypes.string,
}

export default function ForecastMetadata({ shareUrl }) {
    const intl = useIntl()

    let { dateIssued, validUntil } = useReport()
    const forecast = useForecast()

    const TRANSLATIONS = {
        'avalanche-canada': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Avalanche Canada',
        }),
        'avalanche-quebec': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Avalanche Québec',
        }),
        'parks-jasper': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Parks Canada',
        }),
        'parks-byk': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Parks Canada',
        }),
        'parks-waterton': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Parks Canada',
        }),
        'parks-glacier': intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Parks Canada',
        }),
        kananaskis: intl.formatMessage({
            description: 'FX Metadata',
            defaultMessage: 'Kananaskis Mountain Rescue Program',
        }),
    }

    return (
        <Metadata>
            <TimestampEntry
                term={intl.formatMessage({
                    description: 'FX Metadata',
                    defaultMessage: 'Date Issued',
                })}
                value={dateIssued}
            />
            <ValidUntil dateIssued={dateIssued} validUntil={validUntil} />
            <Entry
                term={intl.formatMessage({
                    description: 'FX Metadata',
                    defaultMessage: 'Prepared by',
                })}
            >
                {TRANSLATIONS[forecast?.owner?.value]}
            </Entry>
            {shareUrl && <ShareEntry url={shareUrl} />}
        </Metadata>
    )
}

// Components
ValidUntil.propTypes = {
    validUntil: PropTypes.instanceOf(Date).isRequired,
    dateIssued: PropTypes.instanceOf(Date).isRequired,
}

function ValidUntil({ dateIssued, validUntil }) {
    const intl = useIntl()
    const term = intl.formatMessage({
        description: 'FX Metadata',
        defaultMessage: 'Valid Until',
    })

    return (
        <Entry term={term}>
            {differenceInDays(dateIssued, validUntil) > FURTHER_NOTICE_DAYS ? (
                <span>
                    <FormattedMessage defaultMessage="Until further notice" description="FX Metadata" />
                </span>
            ) : (
                <DateTime value={validUntil} />
            )}
        </Entry>
    )
}
const FURTHER_NOTICE_DAYS = 7
