import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import DangerCard from 'components/graphics/danger'
import * as Hooks from 'hooks'
import Elevations from '@avcan/constants/products/forecast/elevations'
import { VALID_RATINGS } from '@avcan/constants/products/forecast/ratings'
import styles from './Danger.module.css'
import { DATE } from '@avcan/constants/intl/formats'
import * as TimeZoneNames from '@avcan/constants/timeZoneNames'
import RatingStyles from 'styles/forecasts/ratings.module.css'
import ElevationStyles from 'styles/forecasts/elevations.module.css'

Day.propTypes = {
    date: PropTypes.shape({
        value: PropTypes.instanceOf(Date).isRequired,
        display: PropTypes.string.isRequired,
    }).isRequired,
    alp: PropTypes.shape({
        display: PropTypes.string.isRequired,
        rating: PropTypes.shape({
            value: PropTypes.oneOf(VALID_RATINGS).isRequired,
            display: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    tln: PropTypes.shape({
        display: PropTypes.string.isRequired,
        rating: PropTypes.shape({
            value: PropTypes.oneOf(VALID_RATINGS).isRequired,
            display: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    btl: PropTypes.shape({
        display: PropTypes.string.isRequired,
        rating: PropTypes.shape({
            value: PropTypes.oneOf(VALID_RATINGS).isRequired,
            display: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    mountain: PropTypes.bool,
}

export default function Day({ date, mountain, ...ratings }) {
    const [rect, ref] = Hooks.useClientRect(() => Hooks.getWindowSize({ width: 677 }))
    const intl = useIntl()

    return (
        <section ref={ref} className={styles.Day}>
            <header className={styles.Title}>
                <time dateTime={intl.formatDate(date.value, FORMAT)} tabIndex="0">
                    {date.display}
                </time>
            </header>
            {mountain && rect.width > 400 ? <DangerCard {...ratings} /> : <DangerTable {...ratings} />}
        </section>
    )
}

// Utils
function DangerTable(ratings) {
    return Array.from(Elevations, elevation => {
        const { rating, display } = ratings[elevation]

        return <Row key={elevation} rating={rating} elevation={{ value: elevation, display }} />
    })
}
function Row({ rating, elevation }) {
    const ratingStyle = clsx(styles.Rating, RatingStyles[rating.value])
    const elevationStyle = clsx(styles.Elevation, ElevationStyles[elevation.value])

    // TODO Could be moved to a Description List: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl

    return (
        <div className={styles.Row}>
            <div className={elevationStyle} tabIndex="0">
                {elevation.display}
            </div>
            <div className={ratingStyle} tabIndex="0">
                {rating.display}
            </div>
        </div>
    )
}

// Constants
// TODO: Add time zone to the product API.
// It is currenbtly working because we are interested in the date only.
// Adding a time zone will fully fix it
const { weekday, ...FORMAT } = DATE

FORMAT.timeZone = TimeZoneNames.AmericaVancouver
