import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { FormattedMessage, useIntl } from 'react-intl'
import { Sidebar, Header, Contact, Follow, Share, Item, Print, SidebarLink } from 'components/sidebar'
import { Weather, SubmitAMin, Blog, Archive } from 'components/sidebar/AvCanLinks'
import {
    AvalancheTerrainRatings,
    AvalancheTerrainMaps,
    AvalancheForecasting,
    HighwayAvalancheControlProgram,
    BasicSafetyInformation,
} from 'components/sidebar/ParksCanadaLinks'
import { Mailto, Phone } from 'components/anchors'
import { FORECASTERS } from 'constants/emails'
import { LocaleSwitch } from 'components/intl'
import { usePrintable, usePrintURL } from './Context'
import { FR, EN } from '@avcan/constants/locales'
import { AVALANCHE_QUEBEC } from '@avcan/constants/products/owners'

ForecastSidebar.propTypes = {
    isPrintable: PropTypes.bool.isRequired,
}

export default function ForecastSidebar(props) {
    const printable = usePrintable()
    const printURL = usePrintURL()

    return (
        <Sidebar {...props}>
            <Weather />
            <SubmitAMin />
            <Blog />
            <Archive />
            <Follow />
            <Share />
            <Contact email={FORECASTERS} />
            {/* <RSSFeed url={createRSSFeedURL()} /> */}
            {printable && <Print url={printURL} />}
        </Sidebar>
    )
}

export function Kananaskis() {
    const EMAIL = 'avalanche.safety@gov.ab.ca'
    const intl = useIntl()
    const printable = usePrintable()
    const printURL = usePrintURL()

    return (
        <Sidebar>
            <Item>
                <FormattedMessage
                    description="FX Kananaskis Sidebar"
                    defaultMessage="This Avalanche Bulletin is produced by avalanche forecasters
                        within the Government of Alberta, Kananaskis Mountain Rescue Program."
                />
            </Item>
            <Header>
                <FormattedMessage description="FX Kananaskis Sidebar" defaultMessage="Contact" />
            </Header>
            <Item>
                <Mailto
                    email={EMAIL}
                    title={intl.formatMessage({
                        description: 'FX Kananaskis Sidebar',
                        defaultMessage: 'Email the Kananaskis Mountain Rescue Program',
                    })}
                />
            </Item>
            <Item>
                <FormattedMessage
                    description="FX Kananaskis Sidebar"
                    defaultMessage="<phone>403-679-3511</phone> is the Public Safety office phone
                    number (weekdays)"
                    values={{
                        phone(phone) {
                            return <Phone phone={phone} />
                        },
                    }}
                />
            </Item>
            <Item>
                <FormattedMessage
                    description="FX Kananaskis Sidebar"
                    defaultMessage="<phone>403-591-7755</phone> is the dispatch office non-emergency line"
                    values={{
                        phone(phone) {
                            return <Phone phone={phone} />
                        },
                    }}
                />
            </Item>
            <Item>
                <FormattedMessage
                    description="FX Kananaskis Sidebar"
                    defaultMessage="<phone>911</phone> for backcountry rescues and tell them you are in Kananaskis Country"
                    values={{
                        phone(phone) {
                            return <Phone phone={phone} />
                        },
                    }}
                />
            </Item>
            <Header>
                <FormattedMessage description="FX Kananaskis Sidebar" defaultMessage="More information" />
            </Header>
            <Item>
                <SidebarLink href="https://albertaparks.ca/ates/">
                    <FormattedMessage
                        description="FX Kananaskis Sidebar"
                        defaultMessage="Kananaskis Country Avalanche Terrain Exposure Scale (ATES) Ratings"
                    />
                </SidebarLink>
            </Item>
            <Item>
                <SidebarLink href="/weather">
                    <FormattedMessage
                        description="FX Kananaskis Sidebar"
                        defaultMessage="Your daily Mountain Weather Forecast"
                    />
                </SidebarLink>
            </Item>
            <Item>
                <SidebarLink href="/blogs">
                    <FormattedMessage
                        description="FX Kananaskis Sidebar"
                        defaultMessage="Visit Avalanche Canada Blog"
                    />
                </SidebarLink>
            </Item>
            <Item>
                <SidebarLink href="/forecasts/archive">
                    <FormattedMessage description="FX Kananaskis Sidebar" defaultMessage="Forecast Archive" />
                </SidebarLink>
            </Item>
            <Share />
            <Follow
                urls={['https://www.facebook.com/KCPublicSafety', 'https://www.instagram.com/kananaskissafety/?hl=en']}
            />
            <Contact
                email={EMAIL}
                title={intl.formatMessage({
                    description: '',
                    defaultMessage: 'Email the Kananaskis Mountain Rescue Program',
                })}
            />
            {/* <RSSFeed url={createRSSFeedURL()} /> */}
            {printable && <Print url={printURL} />}
        </Sidebar>
    )
}

export function AvalancheQuebec() {
    const printable = usePrintable()
    const { locale, asPath } = useRouter()
    const printURL = usePrintURL()
    const reportURL = locale === EN ? 'https://forms.gle/LkKdHhCQjJtdARqNA' : 'https://forms.gle/7wR5Qe4nGzUSF6SM7'

    return (
        <Sidebar>
            <Item>
                <LocaleSwitch value={FR}>
                    <SidebarLink href={asPath} locale={EN}>
                        English version
                    </SidebarLink>
                </LocaleSwitch>
                <LocaleSwitch value={EN}>
                    <SidebarLink href={asPath} locale={FR}>
                        Version française
                    </SidebarLink>
                </LocaleSwitch>
            </Item>
            <Item>
                <a href="https://avalanchequebec.ca/conditions-chic-chocs#webcamera" target={AVALANCHE_QUEBEC}>
                    <FormattedMessage
                        defaultMessage="Live Chic-Chocs conditions"
                        description="FX Avalanche Quebec Sidebar"
                    />
                </a>
            </Item>
            <Item>
                <a href="https://avalanchequebec.ca/conditions-chic-chocs#rapports-neige" target={AVALANCHE_QUEBEC}>
                    <FormattedMessage
                        defaultMessage="Remote weather stations"
                        description="FX Avalanche Quebec Sidebar"
                    />
                </a>
            </Item>
            <Item>
                <a href="https://avalanchequebec.ca/conditions-chic-chocs#previsions-meteo" target={AVALANCHE_QUEBEC}>
                    <FormattedMessage
                        defaultMessage="Alpine weather forecast"
                        description="FX Avalanche Quebec Sidebar"
                    />
                </a>
            </Item>
            <Item>
                <a href={reportURL} target={AVALANCHE_QUEBEC}>
                    <FormattedMessage
                        defaultMessage="File an avalanche report"
                        description="FX Avalanche Quebec Sidebar"
                    />
                </a>
            </Item>
            <Item>
                <SidebarLink href="/submit">
                    <FormattedMessage
                        defaultMessage="Share today's riding, avalanche, snowpack and weather conditions"
                        description="FX Avalanche Quebec Sidebar"
                    />
                </SidebarLink>
            </Item>
            <Follow urls={['https://www.facebook.com/avalanchequebec', 'https://www.instagram.com/avalanche.quebec']} />
            <Item>
                <a href="https://avalanchequebec.ca/conditions-chic-chocs#mailchimp-widget" target={AVALANCHE_QUEBEC}>
                    <FormattedMessage
                        defaultMessage="Subscribe to our forecasts"
                        description="FX Avalanche Quebec Sidebar"
                    />
                </a>
            </Item>
            <Contact email="info@avalanchequebec.ca" />
            <Item>
                <FormattedMessage
                    description="FX Avalanche Quebec Sidebar"
                    defaultMessage="For backcountry rescue call <phone>911</phone> and tell them you are in the Chic-Chocs"
                    values={{
                        phone(phone) {
                            return <Phone phone={phone} />
                        },
                    }}
                />
            </Item>
            {printable && <Print url={printURL} />}
            <Item>
                <SidebarLink href="/forecasts/archive">
                    <FormattedMessage description="FX Sidebar" defaultMessage="Forecast Archive" />
                </SidebarLink>
            </Item>
        </Sidebar>
    )
}

export function ParksCanadaBYK() {
    const { locale } = useRouter()
    const printable = usePrintable()
    const printURL = usePrintURL()

    const terrainPhotosURL =
        locale === EN
            ? 'https://www.facebook.com/media/set/?vanity=ParksMountainSafety&set=a.504663128373463'
            : 'https://www.facebook.com/media/set/?vanity=parcssecuritemontagne&set=a.496104019211232'
    return (
        <Sidebar>
            <Item>
                <FormattedMessage
                    description="FX Parks Canada Sidebar"
                    defaultMessage="This forecast is produced by Parks Canada avalanche forecasters in Banff, Yoho and Kootenay National Parks."
                />
            </Item>
            <Item>
                <FormattedMessage
                    description="FX Parks Canada Sidebar"
                    defaultMessage="Forecasters <phone>403-762-1470</phone>"
                    values={{
                        phone(phone) {
                            return <Phone phone={phone} />
                        },
                    }}
                />
            </Item>
            <Item>
                <FormattedMessage
                    description="FX Parks Canada Sidebar"
                    defaultMessage="Emergency <phone>911</phone> or <phone>403-762-4506</phone>"
                    values={{
                        phone(phone) {
                            return <Phone phone={phone} />
                        },
                    }}
                />
            </Item>
            <Item>
                <a href={terrainPhotosURL}>
                    <FormattedMessage defaultMessage="Recent terrain photos" description="FX Parks Canada Sidebar" />
                </a>
            </Item>
            <Weather />
            <SubmitAMin />
            <Blog />
            <Archive />
            <AvalancheTerrainRatings />
            <AvalancheTerrainMaps />
            <AvalancheForecasting />
            <HighwayAvalancheControlProgram />
            <BasicSafetyInformation />
            {printable && <Print url={printURL} />}
        </Sidebar>
    )
}

export function ParksCanadaGlacier() {
    const { locale } = useRouter()
    const printable = usePrintable()
    const printURL = usePrintURL()

    return (
        <Sidebar>
            <Item>
                <FormattedMessage
                    description="FX Parks Canada Sidebar"
                    defaultMessage="This forecast is produced by Parks Canada avalanche forecasters in Glacier National Park."
                />
            </Item>
            <Item>
                <FormattedMessage
                    description="FX Parks Canada Sidebar"
                    defaultMessage="Emergency Call <phone>1-877-852-3100</phone> for Parks Canada Dispatch. Tell dispatchers you have a backcountry emergency and require assistance."
                    values={{
                        phone(phone) {
                            return <Phone phone={phone} />
                        },
                    }}
                />
            </Item>
            <Item>
                <a href={`https://www.pc.gc.ca/${locale}/pn-np/bc/glacier/visit/hiver-winter/ski`} target="_self">
                    <FormattedMessage
                        defaultMessage="Ski touring in Rogers Pass and the Winter Permit System"
                        description="FX Parks Canada Sidebar"
                    />
                </a>
            </Item>
            <Item>
                <a href={`https://www.facebook.com/media/set/?set=a.505305314975911&type=3`} target="_self">
                    <FormattedMessage defaultMessage="Recent terrain photos" description="FX Parks Canada Sidebar" />
                </a>
            </Item>
            <Weather />
            <SubmitAMin />
            <Blog />
            <Archive />
            <AvalancheTerrainRatings />
            <AvalancheTerrainMaps />
            <AvalancheForecasting />
            <HighwayAvalancheControlProgram />
            <BasicSafetyInformation />
            {printable && <Print url={printURL} />}
        </Sidebar>
    )
}

export function ParksCanadaJasper() {
    const printable = usePrintable()
    const printURL = usePrintURL()

    return (
        <Sidebar>
            <Item>
                <FormattedMessage
                    description="FX Parks Canada Sidebar"
                    defaultMessage="This forecast is produced by Parks Canada avalanche forecasters in Jasper National Park."
                />
            </Item>
            <Weather />
            <SubmitAMin />
            <Blog />
            <Archive />
            <BasicSafetyInformation />
            {printable && <Print url={printURL} />}
        </Sidebar>
    )
}

export function ParksCanadaWaterton() {
    const printable = usePrintable()
    const printURL = usePrintURL()

    return (
        <Sidebar>
            <Item>
                <FormattedMessage
                    description="FX Parks Canada Sidebar"
                    defaultMessage="This forecast is produced by Parks Canada avalanche forecasters in Waterton National Park."
                />
            </Item>
            <Weather />
            <SubmitAMin />
            <Blog />
            <Archive />
            <BasicSafetyInformation />
            {printable && <Print url={printURL} />}
        </Sidebar>
    )
}

// Utils
// function createRSSFeedURL() {
//     // TODO Find a better way to do that...perhaps using router and ENV var
//     if (typeof document === 'undefined') {
//         return null
//     }

//     const { pathname, origin } = document.location

//     return `${origin}/api${pathname}.rss`
// }
